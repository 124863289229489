import React, { useState, useEffect, useRef, Children } from "react";
import axios from "axios";

import "../Styles/pcstatus.css";

import Comp_CafePC_PCHardware_Log_v2 from "../Component/Comp_CafePC_PCHardware_Log_v2";

import Papa from "papaparse";

// ------------------------------------------------- MUI Table 1/3
import MaterialTable from "material-table";
import {
  Table,
  Button as ButtonMT,
  Checkbox,
  Tooltip,
  Spoiler,
  TextInput,
  Collapse,
} from "@mantine/core";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import { NotificationImportant } from "@material-ui/icons";
import Chart_Calendar from "../Chart/Chart_Calendar";
import Chart_Bar from "../Chart/Chart_Bar";
import Comp_Calendar from "../Chart/Comp_Calendar";

const { REACT_APP_ECLIPSE_API, REACT_APP_ECLIPSE_API_LOCAL } = process.env;

export default function X() {
  //  Get Data from DB -----------------------------------------------------------------------------------------

  const [db_CafeHardware, setBD_CafeHardware] = useState([]);
  const get_DB_CafeHardware_byCafe = async (cafe, _shownHiddenPC) => {
    console.log("shownHiddenPC", _shownHiddenPC);
    await axios
      //REACT_APP_ECLIPSE_API
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_careu__cafe_pc_list_live`, {
        cafe,
        shownHiddenPC: _shownHiddenPC,
      })
      .then((res) => {
        setBD_CafeHardware(res.data[2]);
        console.log("get_DB_CafeHardware_byCafe", res.data[2]);
      });
  };

  const set_DB_Visibility = async (
    id,
    hide_pc,
    cafe, //for reload db use
    shownHiddenPC
  ) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/set__eclipse_careu__cafe_pc_list_live__hidepc__byid`,
        {
          id,
          hide_pc,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          alert(`update successful!`);
          get_DB_CafeHardware_byCafe(cafe, shownHiddenPC);
        }
        console.log(
          "set__eclipse_careu__cafe_pc_list_live__hidepc__byid",
          res.status
        );
      });
  };

  // const [db_CafeHardware_StatusLog, setBD_CafeHardware_StatusLog] = useState(
  //   []
  // );
  // const get_DB_CafeHardware_StatusLog_byCafePCName = async (cafe, pc_name) => {
  //   await axios
  //     //REACT_APP_ECLIPSE_API
  //     .post(
  //       `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__cafe_pc_list_status_log__bycafepcname`,
  //       {
  //         cafe,
  //         pc_name,
  //       }
  //     )
  //     .then((res) => {
  //       setBD_CafeHardware_StatusLog(res.data);
  //       console.log("get_DB_CafeHardware_StatusLog_byCafe", res.data);
  //     });
  // };

  const [db_AllCafeStatus, setDb_AllCafeStatus] = useState([]);
  const get_DB_AllCafe_Status = async (
    date_start,
    date_until,
    get_InfoFrom
  ) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_careu__allcafe_status`, {
        date_start,
        date_until,
        get_InfoFrom,
        shownHiddenPC,
      })
      .then((res) => {
        setDb_AllCafeStatus(res.data);
        console.log("get_DB_AllCafe_Status", res.data);
      });
  };

  const [db_AllCafePCStatus, setDb_AllCafePCStatus] = useState([]);
  const get_DB_AllCafePC_Status = async (cafe, pc_name) => {
    console.log("p1");
    await axios
      //REACT_APP_ECLIPSE_API
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_careu__allcafepc__status`, {
        shownHiddenPC,
      })
      .then((res) => {
        setDb_AllCafePCStatus(res.data);
        console.log("get_DB_AllCafePC_Status", res.data);
      });
    console.log("p2");
  };

  const [db_ServerHD_Info, setDb_ServerHD_Info] = useState([]);
  const get_DB_ServerHD_Info = async (cafe, pc_name) => {
    await axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_golbal_eclipse__eclipse_mon_hd_info__lastest`,
        {}
      )
      .then((res) => {
        setDb_ServerHD_Info(res.data);
        console.log("get_DB_ServerHD_Info", res.data);
      });
  };

  //  -------------------------- Table column

  const [state, setState] = React.useState({
    columns: [
      {
        title: "# NO",
        field: "num",
        minWidth: "1px",
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "5%",
        render: (rowData) => (
          <>
            <div
              style={{
                backgroundColor: rowData?.NIC_Speed.toString().includes("100M")
                  ? "darkred"
                  : "",
              }}
            >{`${rowData?.NIC_Speed}`}</div>
          </>
        ),
      },
      {
        title: "VT",
        field: "CPU_VirtualizationFirmwareEnabled",
        width: "5%",
        render: (rowData) => (
          <div
            style={{
              backgroundColor:
                rowData?.CPU_VirtualizationFirmwareEnabled === "False"
                  ? "darkred"
                  : "",
            }}
          >
            {rowData?.CPU_VirtualizationFirmwareEnabled}
          </div>
        ),
      },
      {
        title: "offlined",
        field: "OFFLINE_Hour",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <div
            style={{
              backgroundColor: rowData?.OFFLINE_Hour > 24 ? "darkred" : "",
            }}
          >
            <div>{`${(rowData?.OFFLINE_Hour / 24).toFixed(1)} Days`}</div>
            <div>{`(${rowData?.OFFLINE_Hour} Hours)`}</div>
            {rowData?.OFFLINE_Hour > 24 && rowData?.hide_pc !== 1 ? (
              <Tooltip
                position="right-start"
                withArrow
                arrowSize={20}
                label={
                  <>
                    <div className="cps_FlexRow">
                      <div style={{ fontSize: "2rem" }}>🚧</div>
                    </div>
                    <div className="cps_FlexColumn">
                      <h2>{`Hide This PC `}</h2>
                      <div>(if no longer exist, data will still keep</div>
                      <div>
                        if the client pc' careu on, this will back to unhide)
                      </div>
                      <div>{`[sid:${rowData?.id}, hidepc:${rowData?.hide_pc}]`}</div>
                    </div>
                  </>
                }
                color="red"
                arrowPosition="center"
              >
                <ButtonMT
                  onClick={() => {
                    if (window.confirm("Hide this PC?")) {
                      // alert("ok");
                      // set pc visibility
                      set_DB_Visibility(
                        rowData?.id,
                        "1",
                        rowData?.cafe,
                        shownHiddenPC
                      );
                    }
                    console.log("rowData", rowData);
                  }}
                  variant="outline"
                  color="red"
                  size="xs"
                >
                  <div style={{ fontSize: "2rem" }}>🚧</div>
                </ButtonMT>
              </Tooltip>
            ) : null}
          </div>
        ),
      },

      { title: "cafe", field: "cafe", width: "5%" },
      { title: "PC_Name", field: "PC_Name", width: "5%" },
      {
        title: "MAC IP",
        field: "NIC_MAPIP",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.NIC_MAC}</div>
            <div> {rowData?.NIC_IP}</div>
          </>
        ),
      },
      {
        title: "PC_StartUpTime",
        field: "PC_StartUpTime",
        width: "5%",

        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            {/* <div>{rowData?.PC_StartUpTime}</div> */}
            <div>
              {" "}
              {`${CompartTime(rowData?.PC_StartUpTime)?.toFixed(0)} Sec`}
            </div>
            <div>
              {CompartTime(rowData?.PC_StartUpTime) < 60 ? "Fast" : null}
            </div>
            <div>
              {CompartTime(rowData?.PC_StartUpTime) >= 60 &&
              CompartTime(rowData?.PC_StartUpTime) < 60 * 2
                ? "Normal"
                : null}
            </div>
            <div>
              {CompartTime(rowData?.PC_StartUpTime) >= 60 * 2 ? "Slow" : null}
            </div>
            <div style={{ fontSize: "2rem" }}>
              {CompartTime(rowData?.PC_StartUpTime) >= 60 * 2 ? "🚨" : null}
            </div>
          </>
        ),
      },
      {
        title: "OS",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.PC_OS_Ver}</div>
            <div> {rowData?.PC_OS}</div>
          </>
        ),
      },

      { title: "MON", field: "MON_Manufacturer", width: "5%" },
      {
        title: "MON HZ",
        field: "mon_fps",
        width: "5%",
        headerStyle: {
          minWidth: "100px",
        },
        render: (rowData) => (
          <div className="cps_FlexColumn">
            <div>{`${rowData?.mon_fps?.split("/")?.[0]} Hz Now `}</div>
            <div>{` ${rowData?.mon_fps?.split("/")?.[1]} Hz Max`}</div>

            <div style={{ fontSize: "2rem" }}>
              {`${
                rowData?.mon_fps?.split("/")?.[1] -
                  rowData?.mon_fps?.split("/")?.[0] >
                1
                  ? "🚨"
                  : ""
              }`}
            </div>
          </div>
        ),
      },
      { title: "CPU", field: "CPU_Name", width: "5%" },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "5%",
      },
      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "5%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div>
              {rowData?.GPU_AdapterRAM}
              {/* {`${rowData?.GPU_AdapterRAM?.replace("|", "") / 1024} GB`} */}
            </div>
          </>
        ),
      },
      {
        title: "MOBO",
        field: "MOBO_Manufacturer",
        width: "5%",
      },
      {
        title: "MOBO 2",
        field: "MOBO_Product",
        width: "5%",
      },
      {
        title: "RAM",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.RAM_Manufacturer}</div>
            {rowData?.RAM_PartNumber?.split(",")?.map((z, zi) => {
              return (
                <ButtonMT
                  key={zi}
                  size="xs"
                  variant="subtle"
                  color="lime"
                  onClick={() => {
                    window.open(
                      `https://www.google.com/search?q=${z}`,
                      "_blank"
                    );
                  }}
                >
                  {z}
                </ButtonMT>
              );
            })}
          </>
        ),
      },

      {
        title: "RAM Capacity",
        field: "RAM_Capacity",
        width: "5%",
        headerStyle: {
          minWidth: "100px",
        },
        render: (rowData) => (
          <div className="cps_FlexColumn">
            <div> {`${rowData?.RAM_Capacity?.split(",")?.[0] / 1024} GB`}</div>
            <div className="cps_FlexRow">
              <div>(</div>

              {rowData?.RAM_Capacity?.split(",")
                ?.slice(1)
                ?.filter((y) => y.length !== 0)
                ?.map((z, zi) => {
                  return (
                    <div key={zi}>
                      {zi !== 0 ? "+" : ""} {z / 1024}GB
                    </div>
                  );
                })}

              <div>)</div>
            </div>
          </div>
        ),
      },
      {
        title: "NIC 1",
        field: "NIC_InstanceName",
        width: "5%",
      },

      {
        title: "created",
        field: "create_dt",
        width: "5%",
      },
      {
        title: "updated",
        field: "update_dt",
        width: "5%",
      },
      {
        title: "careu ver",
        field: "careu_ver",
        width: "5%",
      },
    ],
    columns_sensor: [
      {
        title: "VT",
        field: "CPU_VirtualizationFirmwareEnabled",
        width: "5%",
      },
      {
        title: "offlined",
        field: "OFFLINE_Hour",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div>{`${(rowData?.OFFLINE_Hour / 24).toFixed(1)} Days`}</div>
            <div>{`(${rowData?.OFFLINE_Hour} Hours)`}</div>
          </>
        ),
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "5%",
      },
      { title: "cafe", field: "cafe", width: "5%" },
      { title: "PC_Name", field: "PC_Name", width: "5%" },

      {
        title: "Sensors (15min/sample) def:1d",
        field: "",
        // width: "1%",
        headerStyle: {
          minWidth: "300px",
        },
        render: (rowData) => (
          <>
            <Chart_Bar
              p_data={{
                cafe: rowData?.cafe,
                PC_Name: rowData?.PC_Name,
                // Chart_Title: "cpu_usage",
                // Chart_Colors: "yellow_orange_red",
                sample_amount: 4 * 24,
              }}
            />
          </>
        ),
      },
      {
        title: "NIC Speed",
        field: "NIC_Speed",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.NIC_MAC}</div>
            <div> {rowData?.NIC_IP}</div>
          </>
        ),
      },
      { title: "PC_StartUpTime", field: "PC_StartUpTime", width: "5%" },
      {
        title: "IP & MAC",
        field: "IP & MAC",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.PC_OS_Ver}</div>
            <div> {rowData?.PC_OS}</div>
          </>
        ),
      },

      { title: "MON", field: "MON_Manufacturer", width: "5%" },
      { title: "FPS", field: "mon_fps", width: "5%" },
      { title: "CPU", field: "CPU_Name", width: "5%" },

      {
        title: "GPU",
        field: "GPU_Name",
        width: "5%",
      },
      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "5%",
      },
      {
        title: "MOBO",
        field: "MOBO_Manufacturer",
        width: "5%",
      },
      {
        title: "MOBO 2",
        field: "MOBO_Product",
        width: "5%",
      },
      {
        title: "RAM",
        field: "",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        render: (rowData) => (
          <>
            <div> {rowData?.RAM_Manufacturer}</div>
            <div> {rowData?.RAM_PartNumber}</div>
          </>
        ),
      },

      {
        title: "RAM",
        field: "RAM_Capacity",
        width: "5%",
      },
      {
        title: "NIC 1",
        field: "NIC_InstanceName",
        width: "5%",
      },

      {
        title: "created",
        field: "create_dt",
        width: "5%",
      },
      {
        title: "updated",
        field: "update_dt",
        width: "5%",
      },
      {
        title: "careu ver",
        field: "careu_ver",
        width: "5%",
      },
    ],
  });

  //-------------------------------------------------------------------------- func
  function CompartTime(timeString, notGreaterThen) {
    // Split the time string into hours, minutes, and seconds
    let [hours, minutes, seconds] = timeString.split(":");

    // Convert hours and minutes to seconds and add them up
    let totalSeconds = +hours * 60 * 60 + +minutes * 60 + +seconds;

    // Check if total seconds is greater than 60 (1 minute)
    return totalSeconds;
  }

  //-------------------------------------------------------------------------- global var

  // const [search_Cafe, setSearch_Cafe] = useState("");
  const search_Cafe = useRef("");

  const CareUScanTick_Minute = useRef(15);

  const [shownHiddenPC, setShownHiddenPC] = useState(false);

  const [selectedDate, setSelectedDate] = useState("2024-04-04");

  const [checked_ShowHistoryRecords, setChecked_ShowHistoryRecords] =
    useState(false);

  const [showAdvaceInfo, setShowAdvaceInfo] = useState(false);

  const [selectedRow, setSelectedRow] = useState();
  const [selected_Cafe, setSelected_Cafe] = useState("");

  const [clps_ServerHDSInfo, setClps_ServerHDSInfo] = useState(false);
  const [clps_ServerHDDInfo, setClps_ServerHDDInfo] = useState(false);
  //-------------------------------------------------------------------------- function
  const Filtered_AllCafe = () => {
    if (selected_Cafe === "" || showAdvaceInfo === true) {
      return db_AllCafeStatus;
    }
    if (selected_Cafe !== "" && showAdvaceInfo === false) {
      return db_AllCafeStatus?.filter((x) => {
        return x?.cafe
          ?.toLowerCase()
          ?.includes(selected_Cafe?.toString()?.toLowerCase()?.trim());
      });
    }
  };

  const tablerows = Filtered_AllCafe()?.map((x, xi) => (
    <tr
      key={xi}
      // className="cps_table_pcstatus_row"
      style={{
        backgroundColor: selected_Cafe == x?.cafe ? "rgba(7, 85, 82, 0.9)" : "",
      }}
    >
      {/* Cafe */}
      <td className="cps_glo_width100px">
        <div
          className="cps_glo_Btn_Normal"
          // fullWidth
          onClick={() => {
            console.log("cafe", x?.cafe);
            get_DB_CafeHardware_byCafe(x?.cafe, shownHiddenPC);
            setSelected_Cafe(x?.cafe);
          }}
        >
          {`🔍${x?.cafe}`}
        </div>
      </td>

      {/* LAN 100M List Now!*/}
      <td className="cps_glo_width150px">
        {db_AllCafePCStatus?.[8]
          ?.filter((y) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z, zi) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor: "darkred",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={<>{x?.NIC_Speed}</>}
                  >
                    <div>{`🚨${z?.PC_Name} `}</div>
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>

      {/* LAN 100M List Status*/}
      <td className="cps_glo_width150px">
        {db_AllCafePCStatus?.[2]
          ?.filter((y) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z, zi) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor: z?.LAN_Status_Pctg > 1 ? "darkred" : "",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={
                      <>
                        <div>
                          {`
                    ${(
                      (z?.LAN_100M_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.LAN_Total_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                        </div>

                        <div>
                          {`
               (${z?.LAN_100M_Count} /
                 ${z?.LAN_Total_Count}) Samples `}
                        </div>
                      </>
                    }
                  >
                    <div>{`🚨${z?.PC_Name},  ${z?.LAN_Status_Pctg}% `}</div>
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>

      {/* VT Off Now */}
      <td className="cps_glo_width150px">
        {db_AllCafePCStatus?.[7]
          ?.filter((y) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z, zi) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor: "darkred",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={<>{z?.CPU_VirtualizationFirmwareEnable}</>}
                  >
                    {`🚨${z?.PC_Name} `}
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>
      {/* VT Off Status */}
      <td className="cps_glo_width150px">
        {db_AllCafePCStatus?.[1]
          ?.filter((y) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z, zi) => {
            return (
              <div key={zi}>
                <div
                  style={{
                    backgroundColor:
                      z?.CPU_VIR_Status_Pctg > 1 ? "darkred" : "",
                  }}
                >
                  <Custom_ToolTip
                    c_cafe={x?.cafe}
                    c_pc_name={z?.PC_Name}
                    pcinfo2={
                      <>
                        <div>
                          {`
                    ${(
                      (z?.CPU_VIR_OFF_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.CPU_VIR_Total_Count * CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                        </div>
                        <div>
                          {`
               (${z?.CPU_VIR_OFF_Count} /
                 ${z?.CPU_VIR_Total_Count}) Samples `}
                        </div>
                      </>
                    }
                  >
                    {`🚨${z?.PC_Name},  ${z?.CPU_VIR_Status_Pctg}% `}
                  </Custom_ToolTip>
                </div>
              </div>
            );
          })}
      </td>

      {/* Pc Offline List*/}

      <td className="cps_glo_width100px">
        {db_AllCafePCStatus?.[0]
          ?.filter((y) => {
            return y.cafe === x?.cafe;
          })
          ?.map((z, zi) => {
            return (
              <div
                key={zi}
                style={{
                  backgroundColor: z?.Day_OFF > 1 ? "darkred" : "",
                }}
              >
                <Custom_ToolTip
                  c_cafe={x?.cafe}
                  c_pc_name={z?.PC_Name}
                  pcinfo2={<></>}
                >
                  {`${z?.PC_Name} (${z?.Day_OFF} Days)`}
                </Custom_ToolTip>
              </div>
            );
          })}
      </td>

      {/* Pc Start Up Time */}

      <td className="cps_glo_width100px">
        <div
          style={{
            backgroundColor:
              x?.start_uptime?.split("/")?.[0] > 0 ? "darkgreen" : "",
          }}
        >{`${x?.start_uptime?.split("/")?.[0]}pc =< 1min`}</div>
        <div
          style={{
            backgroundColor: x?.start_uptime?.split("/")?.[1] > 0 ? "" : "",
          }}
        >{`${x?.start_uptime?.split("/")?.[1]}pc =< 2min`}</div>
        <div
          style={{
            backgroundColor:
              x?.start_uptime?.split("/")?.[2] > 0 ? "darkred" : "",
          }}
        >{`${x?.start_uptime?.split("/")?.[2]}pc => 2min`}</div>
      </td>

      {/* GNOFOT */}
      <td>
        <div className="cps_glo_grid_3x3 cps_glo_width300px">
          <div>{`${x?.PC_GUOFOT_Status_15min?.split("/")?.[0]} HC-Game`}</div>
          <div>{`${x?.PC_GUOFOT_Status_15min?.split("/")?.[2]} HC-APP`}</div>
          <div style={{ color: "lime" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[5]
          } PC ON`}</div>

          <div style={{ color: "cyan" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[1]
          } N-Game`}</div>
          <div style={{ color: "cyan" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[3]
          } N-APP`}</div>
          <div style={{ color: "cyan" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[4]
          } Free PC`}</div>

          <div></div>
          <div></div>
          <div style={{ color: "gold" }}>{`${
            x?.PC_GUOFOT_Status_15min?.split("/")?.[6]
          } Total PC`}</div>
        </div>
      </td>

      {/*problems cpu temp*/}
      {showAdvaceInfo ? (
        <td className="cps_glo_width200px">
          {db_AllCafePCStatus?.[3]
            ?.filter((y) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z, zi) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.CPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                    ${(
                      (z?.CPU_Problem_Found_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.CPU_Problem_TotalSample_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                          </div>
                          <div>
                            {`
               (${z?.CPU_Problem_Found_Count} /
                 ${z?.CPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.CPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null}

      {/* problems gpu temp */}
      {showAdvaceInfo ? (
        <td className="cps_glo_width200px">
          {db_AllCafePCStatus?.[4]
            ?.filter((y) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z, zi) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.GPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                    ${(
                      (z?.GPU_Problem_Found_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.GPU_Problem_TotalSample_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                          </div>
                          <div>
                            {`
               (${z?.GPU_Problem_Found_Count} /
                 ${z?.GPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.GPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null}

      {/* bottle-neck cpu usage */}
      {showAdvaceInfo ? (
        <td className="cps_glo_width200px">
          {db_AllCafePCStatus?.[5]
            ?.filter((y) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z, zi) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.CPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                    ${(
                      (z?.CPU_Problem_Found_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    /
                    ${(
                      (z?.CPU_Problem_TotalSample_Count *
                        CareUScanTick_Minute.current) /
                      60
                    )?.toFixed(1)}
                    
                    Hours`}
                          </div>
                          <div>
                            {`
               (${z?.CPU_Problem_Found_Count} /
                 ${z?.CPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.CPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null}

      {/* bottle-neck Gpu usage */}
      {showAdvaceInfo ? (
        <td className="cps_glo_width200px">
          {db_AllCafePCStatus?.[6]
            ?.filter((y) => {
              return y.cafe === x?.cafe;
            })
            ?.map((z, zi) => {
              return (
                <div key={zi}>
                  <div
                    style={{
                      backgroundColor:
                        z?.GPU_Problems_Pctg > 1 ? "darkred" : "",
                    }}
                  >
                    {" "}
                    <Custom_ToolTip
                      c_cafe={x?.cafe}
                      c_pc_name={z?.PC_Name}
                      pcinfo2={
                        <>
                          <div>
                            {`
                  ${(
                    (z?.GPU_Problem_Found_Count *
                      CareUScanTick_Minute.current) /
                    60
                  )?.toFixed(1)}
                  /
                  ${(
                    (z?.GPU_Problem_TotalSample_Count *
                      CareUScanTick_Minute.current) /
                    60
                  )?.toFixed(1)}
                  
                  Hours`}
                          </div>
                          <div>
                            {`
             (${z?.GPU_Problem_Found_Count} /
               ${z?.GPU_Problem_TotalSample_Count}) Samples `}
                          </div>
                        </>
                      }
                    >
                      {`🚨${z?.PC_Name},  ${z?.GPU_Problems_Pctg}% `}
                    </Custom_ToolTip>
                  </div>
                </div>
              );
            })}
        </td>
      ) : null}

      {/* Server HDS Info*/}

      <td className="cps_glo_width300px">
        {/* {console.log(
          "xxx",
          db_ServerHD_Info
            ?.filter((y) => {
              return y.cafe === x?.cafe;
            })?.[0]
            ?.hds_info_str?.split("||")
            ?.filter((b) => b.trim() !== "")
        )} */}
        {db_ServerHD_Info
          ?.filter((y) => {
            return y.cafe === x?.cafe;
          })?.[0]
          ?.hds_info_str?.split("||")
          ?.filter((b) => b.includes("Drive"))
          ?.map((z, zi) => {
            return (
              <div
                className="cps_glo_shiftleft"
                style={{
                  color:
                    z
                      ?.split("|")
                      ?.filter((a) =>
                        a?.includes("Estimated Remaining Lifetime")
                      )?.[0]
                      ?.split(":")?.[1]
                      .trim() != undefined
                      ? ""
                      : "gray",
                }}
              >
                {z
                  ?.split("|")
                  ?.filter((a) =>
                    a?.includes("Estimated Remaining Lifetime")
                  )?.[0]
                  ?.split(":")?.[1]
                  ?.match(/\d+/) < 1000 ? (
                  <>
                    <Tooltip
                      position="right-start"
                      withArrow
                      arrowSize={20}
                      label={
                        <>
                          <br />
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) => a?.includes("Drive"))?.[0]
                            }
                          </div>
                          <div style={{ color: "gold" }}>
                            {`${z
                              ?.split("|")
                              ?.filter((a) => a?.includes("Logical Drive"))?.[0]
                              ?.replace(/\[\]/g, "")} , 
                `}
                          </div>
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) => a?.includes("Size"))?.[0]
                            }
                          </div>
                          <br />
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Hard Disk Serial Number")
                                )?.[0]
                            }
                          </div>
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Firmware Revision")
                                )?.[0]
                            }
                          </div>
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Current Temperature")
                                )?.[0]
                            }
                          </div>
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Maximum Temperature")
                                )?.[0]
                            }
                          </div>
                          <br />
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Power On Time  ")
                                )?.[0]
                            }
                          </div>
                          <div
                            style={{
                              color:
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Estimated Remaining Lifetime")
                                  )?.[0]
                                  ?.split(":")?.[1]
                                  .trim() == undefined
                                  ? ""
                                  : z
                                      ?.split("|")
                                      ?.filter((a) =>
                                        a?.includes(
                                          "Estimated Remaining Lifetime"
                                        )
                                      )?.[0]
                                      ?.split(":")?.[1]
                                      ?.match(/\d+/) < 100
                                  ? "red"
                                  : z
                                      ?.split("|")
                                      ?.filter((a) =>
                                        a?.includes(
                                          "Estimated Remaining Lifetime"
                                        )
                                      )?.[0]
                                      ?.split(":")?.[1]
                                      ?.match(/\d+/) < 1000
                                  ? "orange"
                                  : "",
                            }}
                          >
                            {
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Estimated Remaining Lifetime")
                                )?.[0]
                            }
                          </div>
                          <br />
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) => a?.includes("Performance"))?.[0]
                            }
                          </div>
                          <div>
                            {
                              z
                                ?.split("|")
                                ?.filter((a) => a?.includes("Health"))?.[0]
                            }
                          </div>
                          <br />
                          <div>
                            <div>Statements</div>
                            <div>
                              {z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("hds_statements")
                                )?.[0]
                                ?.split(":")?.[1]
                                ?.split("...")
                                ?.map((x) => {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          backgroundColor: x?.includes("bad")
                                            ? "red"
                                            : x?.includes("errors occured")
                                            ? "darkorange"
                                            : "",
                                          // color: z
                                          //   ?.split("|")
                                          //   ?.filter((a) =>
                                          //     a?.includes("hds_statements")
                                          //   )?.[0]
                                          //   ?.split(":")?.[1]
                                          //   ?.includes("errors occured")
                                          //   ? "red"
                                          //   : "",
                                        }}
                                      >
                                        {x}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                          <br />
                          <div>
                            {`Updated at : ${
                              db_ServerHD_Info?.filter((y) => {
                                return y.cafe === x?.cafe;
                              })?.[0]?.create_at
                            }`}
                          </div>
                        </>
                      }
                    >
                      <div key={zi}>
                        <div>
                          {/* {console.log("z", z)} */}

                          <div className="cps_FlexRow_Pure">
                            <div>
                              {`${z
                                ?.split("|")
                                ?.filter((a) => a?.includes("Drive"))?.[0]
                                ?.split(":")?.[0]
                                ?.split(",")[0]
                                ?.trim()} , 
                `}
                            </div>
                            <div style={{ color: "gold" }}>
                              {`${z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Logical Drive")
                                )?.[0]
                                ?.replace(
                                  "Logical Drive(s)                 :",
                                  ""
                                )
                                ?.replace(/\[\]/g, "")
                                ?.trim()} , 
                `}
                            </div>
                            <div>
                              {`  ${(
                                z
                                  ?.split("|")
                                  ?.filter((a) => a?.includes("Size"))?.[0]
                                  ?.split(":")?.[1]
                                  ?.match(/\d+/)[0] / 1024
                              ).toFixed(1)} GB,
                `}
                            </div>
                            <div
                              style={{
                                color:
                                  z
                                    ?.split("|")
                                    ?.filter((a) =>
                                      a?.includes(
                                        "Estimated Remaining Lifetime"
                                      )
                                    )?.[0]
                                    ?.split(":")?.[1]
                                    .trim() == undefined
                                    ? ""
                                    : z
                                        ?.split("|")
                                        ?.filter((a) =>
                                          a?.includes(
                                            "Estimated Remaining Lifetime"
                                          )
                                        )?.[0]
                                        ?.split(":")?.[1]
                                        ?.match(/\d+/) < 100
                                    ? "red"
                                    : z
                                        ?.split("|")
                                        ?.filter((a) =>
                                          a?.includes(
                                            "Estimated Remaining Lifetime"
                                          )
                                        )?.[0]
                                        ?.split(":")?.[1]
                                        ?.match(/\d+/) < 1000
                                    ? "orange"
                                    : "",
                              }}
                            >
                              {/* {console.log(
                            "xxxxxxx",
                            z
                              ?.split("|")
                              ?.filter((a) =>
                                a?.includes("Estimated Remaining Lifetime")
                              )?.[0]
                              ?.split(":")?.[1]
                          )} */}
                              {`
                            ⌛${
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Estimated Remaining Lifetime")
                                )?.[0]
                                ?.split(":")?.[1]
                            }`}
                            </div>
                          </div>

                          <div style={{ marginLeft: "1rem" }}>
                            <div>
                              {`❤️${
                                z
                                  ?.split("|")
                                  ?.filter((a) => a?.includes("Health"))?.[0]
                                  ?.split(":")?.[1]
                              }:💚${
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Performance")
                                  )?.[0]
                                  ?.split(":")?.[1]
                              }`}
                            </div>
                            <div>
                              {`🌡️${
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Current Temperature")
                                  )?.[0]
                                  ?.split(":")?.[1]
                              }-${
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Maximum Temperature")
                                  )?.[0]
                                  ?.split(":")?.[1]
                                  ?.split(",")?.[0]
                              }`}
                            </div>
                            <div
                              style={{
                                backgroundColor: z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("hds_statements")
                                  )?.[0]
                                  ?.split(":")?.[1]
                                  ?.includes("bad")
                                  ? "red"
                                  : "",
                                // color: z
                                //   ?.split("|")
                                //   ?.filter((a) =>
                                //     a?.includes("hds_statements")
                                //   )?.[0]
                                //   ?.split(":")?.[1]
                                //   ?.includes("errors occured")
                                //   ? "red"
                                //   : "",
                              }}
                            >
                              {`${
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("hds_statements")
                                  )?.[0]
                                  ?.split(":")?.[1]
                                  ?.includes("bad")
                                  ? "🚨 Bad Sector Found !!"
                                  : z
                                      ?.split("|")
                                      ?.filter((a) =>
                                        a?.includes("hds_statements")
                                      )?.[0]
                                      ?.split(":")?.[1]
                                      ?.includes("errors occured")
                                  ? "❗Errors Found!!"
                                  : ""
                              }`}
                            </div>

                            {/* {z?.split("|")?.filter((a) => a?.includes("Fitness"))} */}
                            {/* {z?.split(":")[0] + "\t" + z?.split(":")[1]} */}
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Collapse in={clps_ServerHDSInfo}>
                      <Tooltip
                        position="right-start"
                        withArrow
                        arrowSize={20}
                        label={
                          <>
                            <br />
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) => a?.includes("Drive"))?.[0]
                              }
                            </div>
                            <div style={{ color: "gold" }}>
                              {`${z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Logical Drive")
                                )?.[0]
                                ?.replace(/\[\]/g, "")} , 
                `}
                            </div>
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) => a?.includes("Size"))?.[0]
                              }
                            </div>
                            <br />
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Hard Disk Serial Number")
                                  )?.[0]
                              }
                            </div>
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Firmware Revision")
                                  )?.[0]
                              }
                            </div>
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Current Temperature")
                                  )?.[0]
                              }
                            </div>
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Maximum Temperature")
                                  )?.[0]
                              }
                            </div>
                            <br />
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Power On Time  ")
                                  )?.[0]
                              }
                            </div>
                            <div
                              style={{
                                color:
                                  z
                                    ?.split("|")
                                    ?.filter((a) =>
                                      a?.includes(
                                        "Estimated Remaining Lifetime"
                                      )
                                    )?.[0]
                                    ?.split(":")?.[1]
                                    .trim() == undefined
                                    ? ""
                                    : z
                                        ?.split("|")
                                        ?.filter((a) =>
                                          a?.includes(
                                            "Estimated Remaining Lifetime"
                                          )
                                        )?.[0]
                                        ?.split(":")?.[1]
                                        ?.match(/\d+/) < 100
                                    ? "red"
                                    : z
                                        ?.split("|")
                                        ?.filter((a) =>
                                          a?.includes(
                                            "Estimated Remaining Lifetime"
                                          )
                                        )?.[0]
                                        ?.split(":")?.[1]
                                        ?.match(/\d+/) < 1000
                                    ? "orange"
                                    : "",
                              }}
                            >
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Estimated Remaining Lifetime")
                                  )?.[0]
                              }
                            </div>
                            <br />
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Performance")
                                  )?.[0]
                              }
                            </div>
                            <div>
                              {
                                z
                                  ?.split("|")
                                  ?.filter((a) => a?.includes("Health"))?.[0]
                              }
                            </div>
                            <br />
                            <div>
                              <div>Statements</div>
                              <div>
                                {z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("hds_statements")
                                  )?.[0]
                                  ?.split(":")?.[1]
                                  ?.split("...")
                                  ?.map((x) => {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            backgroundColor: x?.includes("bad")
                                              ? "red"
                                              : x?.includes("errors occured")
                                              ? "darkorange"
                                              : "",
                                            // color: z
                                            //   ?.split("|")
                                            //   ?.filter((a) =>
                                            //     a?.includes("hds_statements")
                                            //   )?.[0]
                                            //   ?.split(":")?.[1]
                                            //   ?.includes("errors occured")
                                            //   ? "red"
                                            //   : "",
                                          }}
                                        >
                                          {x}
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                            <br />
                            <div>
                              {`Updated at : ${
                                db_ServerHD_Info?.filter((y) => {
                                  return y.cafe === x?.cafe;
                                })?.[0]?.create_at
                              }`}
                            </div>
                          </>
                        }
                      >
                        <div key={zi}>
                          <div>
                            {/* {console.log("z", z)} */}

                            <div className="cps_FlexRow_Pure">
                              <div>
                                {`${z
                                  ?.split("|")
                                  ?.filter((a) => a?.includes("Drive"))?.[0]
                                  ?.split(":")?.[0]
                                  ?.split(",")[0]
                                  ?.trim()} , 
                `}
                              </div>
                              <div style={{ color: "gold" }}>
                                {`${z
                                  ?.split("|")
                                  ?.filter((a) =>
                                    a?.includes("Logical Drive")
                                  )?.[0]
                                  ?.replace(
                                    "Logical Drive(s)                 :",
                                    ""
                                  )
                                  ?.replace(/\[\]/g, "")
                                  ?.trim()} , 
                `}
                              </div>
                              <div>
                                {`  ${(
                                  z
                                    ?.split("|")
                                    ?.filter((a) => a?.includes("Size"))?.[0]
                                    ?.split(":")?.[1]
                                    ?.match(/\d+/)[0] / 1024
                                ).toFixed(1)} GB,
                `}
                              </div>
                              <div
                                style={{
                                  color:
                                    z
                                      ?.split("|")
                                      ?.filter((a) =>
                                        a?.includes(
                                          "Estimated Remaining Lifetime"
                                        )
                                      )?.[0]
                                      ?.split(":")?.[1]
                                      .trim() == undefined
                                      ? ""
                                      : z
                                          ?.split("|")
                                          ?.filter((a) =>
                                            a?.includes(
                                              "Estimated Remaining Lifetime"
                                            )
                                          )?.[0]
                                          ?.split(":")?.[1]
                                          ?.match(/\d+/) < 100
                                      ? "red"
                                      : z
                                          ?.split("|")
                                          ?.filter((a) =>
                                            a?.includes(
                                              "Estimated Remaining Lifetime"
                                            )
                                          )?.[0]
                                          ?.split(":")?.[1]
                                          ?.match(/\d+/) < 1000
                                      ? "orange"
                                      : "",
                                }}
                              >
                                {/* {console.log(
                            "xxxxxxx",
                            z
                              ?.split("|")
                              ?.filter((a) =>
                                a?.includes("Estimated Remaining Lifetime")
                              )?.[0]
                              ?.split(":")?.[1]
                          )} */}
                                {`
                            ⌛${
                              z
                                ?.split("|")
                                ?.filter((a) =>
                                  a?.includes("Estimated Remaining Lifetime")
                                )?.[0]
                                ?.split(":")?.[1]
                            }`}
                              </div>
                            </div>

                            <div style={{ marginLeft: "1rem" }}>
                              <div>
                                {`❤️${
                                  z
                                    ?.split("|")
                                    ?.filter((a) => a?.includes("Health"))?.[0]
                                    ?.split(":")?.[1]
                                }:💚${
                                  z
                                    ?.split("|")
                                    ?.filter((a) =>
                                      a?.includes("Performance")
                                    )?.[0]
                                    ?.split(":")?.[1]
                                }`}
                              </div>
                              <div>
                                {`🌡️${
                                  z
                                    ?.split("|")
                                    ?.filter((a) =>
                                      a?.includes("Current Temperature")
                                    )?.[0]
                                    ?.split(":")?.[1]
                                }-${
                                  z
                                    ?.split("|")
                                    ?.filter((a) =>
                                      a?.includes("Maximum Temperature")
                                    )?.[0]
                                    ?.split(":")?.[1]
                                    ?.split(",")?.[0]
                                }`}
                              </div>
                              <div
                                style={{
                                  backgroundColor: z
                                    ?.split("|")
                                    ?.filter((a) =>
                                      a?.includes("hds_statements")
                                    )?.[0]
                                    ?.split(":")?.[1]
                                    ?.includes("bad")
                                    ? "red"
                                    : "",
                                  // color: z
                                  //   ?.split("|")
                                  //   ?.filter((a) =>
                                  //     a?.includes("hds_statements")
                                  //   )?.[0]
                                  //   ?.split(":")?.[1]
                                  //   ?.includes("errors occured")
                                  //   ? "red"
                                  //   : "",
                                }}
                              >
                                {`${
                                  z
                                    ?.split("|")
                                    ?.filter((a) =>
                                      a?.includes("hds_statements")
                                    )?.[0]
                                    ?.split(":")?.[1]
                                    ?.includes("bad")
                                    ? "🚨 Bad Sector Found !!"
                                    : z
                                        ?.split("|")
                                        ?.filter((a) =>
                                          a?.includes("hds_statements")
                                        )?.[0]
                                        ?.split(":")?.[1]
                                        ?.includes("errors occured")
                                    ? "❗Errors Found!!"
                                    : ""
                                }`}
                              </div>

                              {/* {z?.split("|")?.filter((a) => a?.includes("Fitness"))} */}
                              {/* {z?.split(":")[0] + "\t" + z?.split(":")[1]} */}
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </Collapse>
                  </>
                )}
              </div>
            );
          })}
        <ButtonMT
          fullWidth
          variant="subtle"
          color="teal"
          onClick={() => setClps_ServerHDSInfo((o) => !o)}
        >
          {clps_ServerHDSInfo ? "🙈 hide" : "🔍 more..."}
        </ButtonMT>
      </td>

      {/* Server HDD Info*/}

      <td className="cps_glo_width150px ">
        {
          db_ServerHD_Info
            ?.filter((y) => {
              return y.cafe === x?.cafe;
            })?.[0]
            ?.hdd_info_str?.split("||")
            ?.filter((b) => b.trim() !== "")
            ?.map((z) => {
              return (
                <div className="cps_glo_shiftleft">
                  {z?.split("|")[1]?.split("/")?.[0] <= 50 ? (
                    <>
                      <Tooltip
                        position="right-start"
                        withArrow
                        arrowSize={20}
                        label={
                          <>
                            <div>{`${z?.split("|")[0]} Drive`}</div>
                            <div>{`${z?.split("|")[1]} GB`}</div>
                            <div>{`${z?.split("|")[2]} % FREE SPACE`}</div>
                            <br />
                            <div>{`Updated at : ${
                              db_ServerHD_Info?.filter((y) => {
                                return y.cafe === x?.cafe;
                              })?.[0]?.create_at
                            } `}</div>
                          </>
                        }
                      >
                        <div
                          style={{
                            color:
                              z?.split("|")[1]?.split("/")?.[0] <= 50 &&
                              z?.split("|")[1]?.split("/")?.[0] > 30
                                ? "gold"
                                : z?.split("|")[1]?.split("/")?.[0] <= 30 &&
                                  z?.split("|")[1]?.split("/")?.[0] > 10
                                ? "orange"
                                : z?.split("|")[1]?.split("/")?.[0] <= 10
                                ? "darkred"
                                : "",
                          }}
                        >
                          <div style={{ color: "gold" }}>
                            {`${z?.split("|")[0]}
                  `}
                          </div>
                          <div>
                            {`
                  ${z?.split("|")[1]?.split("/")[0]} GB FREE`}
                          </div>
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Collapse in={clps_ServerHDDInfo}>
                        <Tooltip
                          position="right-start"
                          withArrow
                          arrowSize={20}
                          label={
                            <>
                              <div>{`${z?.split("|")[0]} Drive`}</div>
                              <div>{`${z?.split("|")[1]} GB`}</div>
                              <div>{`${z?.split("|")[2]} % FREE SPACE`}</div>
                              <br />
                              <div>{`Updated at : ${
                                db_ServerHD_Info?.filter((y) => {
                                  return y.cafe === x?.cafe;
                                })?.[0]?.create_at
                              } `}</div>
                            </>
                          }
                        >
                          <div
                            style={{
                              color:
                                z?.split("|")[1]?.split("/")?.[0] <= 50 &&
                                z?.split("|")[1]?.split("/")?.[0] > 30
                                  ? "gold"
                                  : z?.split("|")[1]?.split("/")?.[0] <= 30 &&
                                    z?.split("|")[1]?.split("/")?.[0] > 10
                                  ? "orange"
                                  : z?.split("|")[1]?.split("/")?.[0] <= 10
                                  ? "darkred"
                                  : "",
                            }}
                          >
                            <div style={{ color: "gold" }}>
                              {`${z?.split("|")[0]}
                  `}
                            </div>
                            <div>
                              {`
                  ${z?.split("|")[1]?.split("/")[0]} GB FREE`}
                            </div>
                          </div>
                        </Tooltip>
                      </Collapse>
                    </>
                  )}
                </div>
              );
            })
          // ?.map((z) => {
          //   <div>{z}</div>;
          // })
        }
        <ButtonMT
          fullWidth
          variant="subtle"
          color="teal"
          onClick={() => setClps_ServerHDDInfo((o) => !o)}
        >
          {clps_ServerHDDInfo ? "🙈 hide" : "🔍 more..."}
        </ButtonMT>
      </td>

      {/* total pc per total hour */}
      {/* {showAdvaceInfo ? (
        <td className="glo_width1000px">
          <Chart_Calendar cafe={x?.cafe} />
        </td>
      ) : null} */}
    </tr>
  ));

  // ---------------------------------------------------------  Chart

  // ---------------------------------------------------------  Dialog

  const [dialog_Show, setDialog_Show] = useState(false);
  const handleClose_Dialog_Show = () => {
    setDialog_Show(false);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
      backgroundColor: "darkred",
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    reloadBD();
    console.log("p-1");
  }, [shownHiddenPC]);

  const reloadBD = () => {
    console.log("reloadBD");
    console.log("p0");
    get_DB_AllCafePC_Status();
    console.log("p4");
    get_DB_AllCafe_Status("", "", "current");
    get_DB_CafeHardware_byCafe();
    get_DB_ServerHD_Info();
  };

  useEffect(() => {
    if (checked_ShowHistoryRecords) {
      setDb_AllCafeStatus([]);
      get_DB_AllCafe_Status(
        `${selectedDate} 00:00:00`,
        `${selectedDate} 23:59:59`,
        "history"
      );
    }
  }, [selectedDate, checked_ShowHistoryRecords]);

  //-------------------------------------------------------------------------- main return()
  const [showSensor, setShowSensor] = useState(false);

  return (
    <>
      <Checkbox
        size="xl"
        checked={checked_ShowHistoryRecords}
        onChange={(event) =>
          setChecked_ShowHistoryRecords(event.currentTarget.checked)
        }
        label={
          <div
            style={{ color: "lime" }}
          >{`(BETA 10-15)Use History Record :${selectedDate}`}</div>
        }
        color="lime"
      />
      {checked_ShowHistoryRecords ? (
        <Comp_Calendar CB_selectedDate={setSelectedDate} />
      ) : null}
      <br />
      <div className="cps_FlexRow_Pure">
        <ButtonMT
          variant=""
          color="teal"
          onClick={() => {
            setShowAdvaceInfo(!showAdvaceInfo);
            console.log("pz", db_AllCafePCStatus);
            // setSelected_Cafe("");
            // setBD_CafeHardware([]);
          }}
        >
          {`${showAdvaceInfo ? "Hide" : "Show"} ⭐Advance Info`}
        </ButtonMT>

        <ButtonMT
          disabled={selected_Cafe === ""}
          variant=""
          color="lime"
          onClick={() => {
            setSelected_Cafe("");
            setBD_CafeHardware([]);
          }}
        >
          {`<< Show All Cafe`}
        </ButtonMT>

        <Tooltip
          position="right-start"
          withArrow
          arrowSize={20}
          label={
            <>
              <div className="cps_FlexRow">
                <div style={{ fontSize: "2rem" }}>🚧</div>
              </div>
              <div className="cps_FlexColumn">
                <h2>{`Show/Hide ALL 🚧 PC `}</h2>
                <div>(if no longer exist, data will still keep</div>
                <div>if the client pc' careu on, this will back to unhide)</div>
              </div>
            </>
          }
          color="gray"
          arrowPosition="center"
        >
          <ButtonMT
            variant={shownHiddenPC ? "filled" : "subtle"}
            color="gray"
            onClick={() => setShownHiddenPC(!shownHiddenPC)}
          >
            {shownHiddenPC ? "Showing" : "Set Show"}
            <div style={{ fontSize: "2rem" }}>🚧</div>
          </ButtonMT>
        </Tooltip>

        <TextInput
          placeholder="Key in Cafe Name"
          autoFocus={true}
          description={`🔍${tablerows?.length} Cafes Selected`}
          // value={search_Cafe.current}
          onChange={(event) => {
            // (search_Cafe.current = event.currentTarget.value)
            setSelected_Cafe(event.currentTarget.value);

            // onKeyDown={(event) => {
            //   if (event.key === "Enter") {
            //     console.log("enter");
            //     // search_Cafe.current = event.currentTarget.value;
            //     setSelected_Cafe(event.currentTarget.value);
            //   }
          }}
        />
      </div>
      <br />
      <div
        style={{ color: "gray" }}
      >{`Each PC Sampling Collected Every ${CareUScanTick_Minute?.current} minutes`}</div>
      <div
        style={{ color: "gray" }}
      >{`Status Pool Update Every ${CareUScanTick_Minute?.current} minutes`}</div>
      <br />

      <div>
        <Table
          className="cps_table_pcstatus"
          verticalSpacing="xs"
          fontSize="xs"
        >
          <thead>
            <tr>
              <th>Cafe</th>
              <th>
                <div>LAN 100M - NOW!</div>
              </th>
              <th>
                <div>LAN 100M % </div>
              </th>
              <th>VT Off- NOW!</th>
              <th>VT Off % </th>
              <th>Pc Offline List</th>
              <th>Pc Start Up Time</th>
              <th>
                <div>GNOFOT</div>
                <div>(◀︎ 15min)</div>
              </th>
              {showAdvaceInfo ? (
                <th>
                  <div>⭐CPU</div>
                  <div>(▶︎90c & ◀︎50%)</div>
                  <Tooltip
                    position="right-start"
                    withArrow
                    arrowSize={20}
                    label="ie. Showing the probability that CPU have Temperature issues, base on the total samples collected."
                  >
                    <div style={{ color: "gray" }}>explaination</div>
                  </Tooltip>
                </th>
              ) : null}
              {showAdvaceInfo ? (
                <th>
                  <div>⭐GPU</div>
                  <div>(▶︎85c & ◀︎50%)</div>
                </th>
              ) : null}
              {showAdvaceInfo ? (
                <th>⭐CPU BottleNeck (▶︎90c & ▶︎90%)</th>
              ) : null}
              {showAdvaceInfo ? (
                <th>⭐GPU BottleNeck (▶︎90c & ▶︎90%)</th>
              ) : null}
              {/* {showAdvaceInfo ? (
                <th>⭐Total PC (ALL/Gaming/Online) Hour / pc</th>
              ) : null} */}
              <th>Server HDS Info</th>
              <th>Server HDD Info</th>
            </tr>
          </thead>
          <tbody>{tablerows}</tbody>
        </Table>
      </div>
      <br />
      <div style={{ backgroundColor: "gold" }}></div>
      <br />
      <br />
      <div>
        {db_CafeHardware?.length > 0 && db_AllCafeStatus?.length > 0 ? (
          <div>
            <MaterialTable
              title={
                <div className="cps_FlexRow">
                  <h2 style={{ color: "#C5ECCD" }}>
                    <div>
                      {`Cafe PC Status : ${selected_Cafe} ( ${db_CafeHardware?.length} PCS )`}{" "}
                      <ButtonMT
                        variant="subtle"
                        onClick={() => setShowSensor(!showSensor)}
                      >
                        Show Sensors Charts : {showSensor ? "Show" : "Hidden"}
                      </ButtonMT>
                    </div>
                  </h2>
                </div>
              }
              columns={
                showSensor === true ? state.columns_sensor : state.columns
              }
              data={db_CafeHardware}
              style={{
                color: "white",
                background: "rgba(36, 43, 38, .5)",
                // backgroundColor: "rgba(0,0,0,.1)",
                // backgroundColor:
                //   "linear-gradient(90deg,rgba(25, 30, 26,.3) .25%, rgba(0, 0, 0,.5) .25%)",
                // backdropFilter: "blur( 11.0px )",
                // "&::webkit-backdrop-filter": {
                //   webkitBackdropFilter: "blur( 18.0px )",
                // },
              }}
              options={{
                exportButton: true,

                headerStyle: {
                  background: "rgba(54, 64, 56,.5)",
                  border: "none",
                },
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData?.hide_pc === 1 ? "darkred" : "rgba(25, 30, 26,.5)",

                  color:
                    rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
                  // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
                  fontSize: 12,
                }),
                filtering: true,
                grouping: true,
                exportAllData: true,
                pageSize: 100,
                pageSizeOptions: [20, 50, 100, 200, 500, 1000],
                paginationType: "stepped",
                // actionsColumnIndex: -1
                // fixedColumns: {
                //     left: 1,
                // }
                exportCsv: (columns, data) => {
                  // console.log("columns", columns);
                  // console.log("data", data);
                  // alert('You should develop a code to export ' + data.length + ' rows');

                  //------------------------------- format data
                  function processData(data) {
                    // Split the data string into an array
                    let dataArray = data.split(",");

                    // Remove the first and empty elements
                    dataArray.shift();
                    dataArray.pop();

                    // Check if the elements are numbers and sum them up
                    let sum = 0;
                    let elements = [];
                    for (let i = 0; i < dataArray.length; i++) {
                      let element = parseInt(dataArray[i]);
                      if (!isNaN(element)) {
                        sum += element / 1024;
                        elements.push(element / 1024);
                      }
                    }

                    // Format the result
                    let result = sum + "GB (" + elements.join("+") + ")";

                    return result;
                  }

                  let data2 = data?.map((x, xi) => {
                    console.log("x?.RAM_Capacity", x?.RAM_Capacity);
                    let formated_RAM_Capacity = processData(x?.RAM_Capacity); //x?.RAM_Capacity?.split(",")[0];

                    let formated_GPU_AdapterRAM =
                      x?.GPU_AdapterRAM?.split("|")[0] / 1024 + " GB";

                    let formated_OFFLINE_Hour =
                      (x?.OFFLINE_Hour / 24)?.toFixed(1) + " Days";

                    return {
                      ...x,
                      RAM_Capacity: formated_RAM_Capacity,
                      GPU_AdapterRAM: formated_GPU_AdapterRAM,
                      OFFLINE_Hour: formated_OFFLINE_Hour,
                    };
                  });
                  // console.log("data2", data2);
                  //------------------------------- format file name
                  function getTodayString(recordCount) {
                    const date = new Date();
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, "0"); // JS months start from 0
                    const day = String(date.getDate()).padStart(2, "0");
                    const hours = String(date.getHours()).padStart(2, "0");
                    const minutes = String(date.getMinutes()).padStart(2, "0");
                    const seconds = String(date.getSeconds()).padStart(2, "0");

                    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} Total ${recordCount} records.csv`;
                  }

                  //------------------------------- export json to csv
                  const csv = Papa.unparse(data2);
                  const blob = new Blob([csv], { type: "text/csv" });
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    `${getTodayString(data2?.length)}.csv`
                  );
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                },
              }}
              onRowClick={(evt, selectedRow) => {
                // console.log(selectedRow.id);
                // setSelected_Item(selectedRow);
                // handleClickOpen_1_1();
                // setSelected_Input_OrderId(selectedRow.order_id);
                // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
                // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
              }}
              detailPanel={[
                {
                  tooltip: "Show Hardware Changes Log",
                  render: (rowData) => {
                    // console.log("rowData", rowData);
                    return (
                      <div>
                        <Comp_CafePC_PCHardware_Log_v2
                          cafe={rowData?.cafe}
                          pc_name={rowData?.PC_Name}
                        />
                      </div>
                    );
                  },
                },
              ]}
              actions={
                [
                  // {
                  //   icon: "save",
                  //   tooltip: "Save User",
                  //   onClick: (event, rowData) => {
                  //     // get_DB_CafeHardware_StatusLog_byCafePCName(
                  //     //   rowData?.cafe,
                  //     //   rowData?.PC_Name
                  //     // );
                  //     setSelectedRow(rowData);
                  //     setDialog_Show(true);
                  //     console.log("rowData", rowData);
                  //   },
                  // },
                ]
              }
              components={
                {
                  // Action: (props) => (
                  //   <Button
                  //     // disabled
                  //     onClick={(event) => props.action.onClick(event, props.data)}
                  //     color="inherit"
                  //     variant="text"
                  //     style={{ textTransform: "none" }}
                  //     size="large"
                  //   >
                  //     <div style={{ fontSize: "2rem" }}>🚧</div>
                  //   </Button>
                  // ),
                }
              }
            />
          </div>
        ) : (
          <div>Please Select a Cafe From Above List Loading...</div>
        )}
      </div>
    </>
  );
}

const Custom_ToolTip = ({ children, c_cafe, c_pc_name, pcinfo2 }) => {
  const [db_Data, setDb_Data] = useState([]);
  const get_DB_Pcinfo__bycafepc = (cafe, PC_Name) => {
    axios
      //REACT_APP_ECLIPSE_API
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__pcinfo__bycafeandpcname`,
        {
          cafe,
          PC_Name,
        }
      )
      .then((res) => {
        // console.log("get_DB_Pcinfo__bycafepc", res.data?.[0]);
        setDb_Data(res.data?.[0]);
      });
  };

  useEffect(() => {
    get_DB_Pcinfo__bycafepc(c_cafe, c_pc_name);
  }, []);

  return (
    <Tooltip
      color="darkcyan"
      position="right-start"
      withArrow
      arrowSize={20}
      label={
        <div>
          <div>{`⏱️ last status uptate ${db_Data?.timepast} minute ago`} </div>
          <br />
          <div>{`${db_Data?.cafe} ${db_Data?.PC_Name}`} </div>
          <hr />
          <div>{`CPU  : ${db_Data?.CPU_Name}`}</div>
          <div>{`GPU  : ${db_Data?.GPU_Name}`}</div>
          <div>{`VRAM : ${
            parseInt(db_Data?.GPU_AdapterRAM?.replace("|")) / 1024
          } GB`}</div>
          <div>{`RAM  : ${
            parseInt(db_Data?.RAM_Capacity?.split(",")?.[0]) / 1024
          } GB (  ${db_Data?.RAM_Capacity?.split(",")
            ?.filter((x) => x != "")
            ?.slice(1)
            ?.map((x) => `${x / 1024} GB`)}  )`}</div>
          <hr />
          {pcinfo2}
        </div>
      }

      // openDelay={500}
      // closeDelay={500}
    >
      <div>{children}</div>
    </Tooltip>
  );
};
