import React, { useState, useEffect } from "react";
import axios from "axios";

import { Modal, Button as ButtonMT, Group } from "@mantine/core";

// ------------------------------------------------- MUI Table 1/3
import MaterialTable from "material-table";
import { TextInput, Textarea, Button } from "@mantine/core";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

export default function X({ cafe, pc_name }) {
  console.log("props 1", cafe);
  console.log("props 2", pc_name);

  //  Get Date from DB -----------------------------------------------------------------------------------------

  const [dbData, setDbData] = useState([]);
  const getData = () => {
    axios
      .post(
        //REACT_APP_ECLIPSE_API
        `${REACT_APP_ECLIPSE_API}/get_eclipse_careu__cafe_pc_list_log__bycafepcname`,
        { cafe, pc_name }
      )
      .then((res) => {
        setDbData(res?.data);
        console.log("getData", res?.data);
      });
  };

  const [DB_PCHistory, setDB_PCHistory] = useState([]);
  const getDB_PC_History = (NIC_MAC) => {
    axios
      .post(
        //REACT_APP_ECLIPSE_API
        `${REACT_APP_ECLIPSE_API}/get__cafe_pc_list_log__pcinfo__bymac`,
        { NIC_MAC }
      )
      .then((res) => {
        setDB_PCHistory(res?.data);
        console.log("getDB_PC_History", res?.data);
      });
  };

  //  -------------------------- Table column

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Log Datetime",
        field: "create_dt",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "change reason",
        field: "spec_changed_reason",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => <>{rowData?.spec_changed_reason}</>,
      },
      {
        title: "change items",
        field: "spec_changed",
        width: "10%",
        headerStyle: {
          minWidth: "200px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            {rowData?.spec_changed_reason === "pc spec changed"
              ? format_PcSpecChanged2(rowData?.spec_changed)
              : format_NewPc2(rowData?.spec_changed)}
          </>
        ),
      },

      // {
      //   title: "spec_changed",
      //   field: "spec_changed",
      //   width: "1%",
      // },
      {
        title: "Remark",
        field: "remark",
        width: "20%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <Textarea
              disabled
              placeholder={rowData?.remark}
              autosize
              minRows={2}
              onChange={
                (event) => {}
                // setValue(event.currentTarget.value)
              }
            />
            <ButtonMT disabled>Submit Remark</ButtonMT>
            <ButtonMT disabled>Set Alert</ButtonMT>
          </>
        ),
      },
      {
        title: "remark by",
        field: "remark_by",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Remark at",
        field: "remark_dt",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
    ],

    columns2: [
      {
        title: "id",
        field: "id",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      //, remark, remark_by, remark_dt
      {
        title: "Spec Changed Reason",
        field: "spec_changed_reason",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Create at",
        field: "create_dt",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "Cafe",
        field: "cafe",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "PC Name",
        field: "PC_Name",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "MAC",
        field: "NIC_MAC",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
        render: (rowData) => (
          <>
            <div style={{ color: "gold" }}>{rowData?.NIC_MAC}</div>
          </>
        ),
      },
      {
        title: "IP",
        field: "NIC_IP",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "CPU",
        field: "CPU_Name",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "GPU",
        field: "GPU_Name",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "GPU RAM",
        field: "GPU_AdapterRAM",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "MOBO",
        field: "MOBO_Product",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "RAM ",
        field: "RAM_Manufacturer",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
      {
        title: "RAM Capacity",
        field: "RAM_Capacity",
        width: "1%",
        headerStyle: {
          minWidth: "1px",
        },
        cellStyle: {
          fontSize: 10,
        },
      },
    ],
  });

  //-------------------------------------------------------------------------- function
  function format_PcSpecChanged(_data) {
    let data = JSON.parse(_data);

    let formattedData = "";
    for (let prop in data) {
      formattedData += `${prop}:\n`;
      for (let subProp in data[prop]) {
        formattedData += `${subProp}: ${data[prop][subProp]}\n`;
      }
      formattedData += "\n";
    }
    return formattedData;
  }

  const format_NewPc2 = (data) => {
    let parsedData = JSON.parse(data);

    delete parsedData.careu_ver;
    delete parsedData.PC_StartUpTime;
    delete parsedData.PC_TotalUpTime;
    delete parsedData.PC_OS;
    delete parsedData.PC_OS_Ver;
    delete parsedData.mon_fps;

    delete parsedData.CPU_ProcessorID;
    delete parsedData.CPU_VirtualizationFirmwareEnabled;
    delete parsedData.NIC_Speed;

    delete parsedData.MOBO_Fan_Speed;
    delete parsedData.MOBO_Temperature;
    delete parsedData.CPU_Usage;
    delete parsedData.CPU_Temperature;
    delete parsedData.RAM_Usage;
    delete parsedData.GPU_Usage;
    delete parsedData.GPU_Temperature;
    delete parsedData.GPU_Fan_Speed;
    delete parsedData.GPU_VRAM_Usage;

    console.log("parsedData", parsedData);

    return (
      <div>
        {parsedData && (
          <table>
            <thead>
              <tr>
                <th> </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(parsedData).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <br />
        <ButtonMT
          variant="subtle"
          color="lime"
          onClick={() => {
            setOpened(true);
            // console.log("xxxxx", parsedData?.NIC_MAC);
            getDB_PC_History(parsedData?.NIC_MAC);
          }}
        >
          {`Check History (NIC_MAC: ${parsedData?.NIC_MAC} )`}
        </ButtonMT>
      </div>
    );
  };

  function format_PcSpecChanged2(htmlString) {
    const parsedData = JSON.parse(htmlString);
    // console.log("parsedData2", parsedData);
    const FormatRAMInfo = (data) => {
      const formatRAMCapacity = (ramString) => {
        if (!ramString) return "RAM_Capacity not provided";

        let ramArray = ramString.split(",").filter(Boolean).map(Number);
        let total = ramArray[0] / 1024;
        let units = ramArray
          .slice(1)
          .map((val) => val / 1024)
          .join(",");

        return `${total} GB (${units})`;
      };

      let result = {};
      for (let key in data) {
        result[key] = key.startsWith("RAM_Capacity")
          ? formatRAMCapacity(data[key])
          : data[key] || `${key} not provided`;
      }
      return result;
    };

    const renderSection = (section, title) => (
      <table>
        <thead>
          <tr>
            <th
              style={{ color: title === "From" ? "gold" : "cyan" }}
              colSpan="2"
            >
              {title}
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(section).map(([key, value], i) => (
            <tr key={i}>
              <td>{key}</td>
              <td style={{ color: title === "From" ? "gold" : "cyan" }}>
                <div style={{ color: value === "" ? "red" : "" }}>
                  {value === "" ? "EMPTY" : value}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
    return (
      <div>
        {renderSection(FormatRAMInfo(parsedData.from), "From")}

        <br />
        {parsedData.from?.NIC_MAC !== null &&
        parsedData.to?.NIC_MAC !== undefined ? (
          <ButtonMT
            variant="subtle"
            color="lime"
            onClick={() => {
              setOpened(true);
              console.log("xxxxx", parsedData.from?.NIC_MAC);
              getDB_PC_History(parsedData.from?.NIC_MAC);
            }}
          >
            {`Check History (from NIC_MAC: ${parsedData.from?.NIC_MAC} )`}
          </ButtonMT>
        ) : null}
        <br />

        {renderSection(FormatRAMInfo(parsedData.to), "To")}

        {parsedData.to?.NIC_MAC !== null &&
        parsedData.to?.NIC_MAC !== undefined ? (
          <ButtonMT
            variant="subtle"
            color="lime"
            onClick={() => {
              setOpened(true);
              console.log("xxxxx", parsedData.to?.NIC_MAC);
              getDB_PC_History(parsedData.to?.NIC_MAC);
            }}
          >
            {`Check History (to NIC_MAC: ${parsedData.to?.NIC_MAC} )`}
          </ButtonMT>
        ) : null}
      </div>
    );
  }

  //-------------------------------------------------------------------------- Global Var
  const [opened, setOpened] = useState(false);

  //-------------------------------------------------------------------------- useEffect
  useEffect(() => {
    getData();
  }, []);

  //-------------------------------------------------------------------------- main return()
  return (
    <>
      <Modal
        fullScreen
        opened={opened}
        onClose={() => setOpened(false)}
        title="Introduce yourself!"
      >
        <br />
        <br />
        <ButtonMT
          onClick={() => {
            setOpened(false);
          }}
        >
          Close
        </ButtonMT>
        <MaterialTable
          title={`Hardware Log 1 (${dbData?.length})  `}
          columns={state.columns2}
          data={DB_PCHistory}
          style={{
            color: "white",
            background: "rgba(0, 0, 0, 1)",
            // backdropFilter: "blur( 11.0px )",
            // "&::webkit-backdrop-filter": {
            //   webkitBackdropFilter: "blur( 18.0px )",
            // },
          }}
          options={{
            exportButton: true,
            headerStyle: {
              background: "rgba(0, 0, 0, 1)",

              border: "none",
            },
            // rowStyle: (rowData) => ({
            //   backgroundColor:
            //     selectedRow === rowData.tableData.id ? "#422400" : "",
            //   fontSize: 12,
            // }),
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData?.Item_stock_balance < 0
                  ? "#ff0000"
                  : rowData.Item_stock_balance === 0
                  ? "#1a0000"
                  : "",
              color: rowData?.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
              // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
              fontSize: 12,
            }),
            filtering: true,
            // grouping: true,
            exportAllData: true,
            pageSize: 100,
            pageSizeOptions: [20, 50, 100, 200, 500, 1000],
            paginationType: "stepped",
            // actionsColumnIndex: -1
            // fixedColumns: {
            //     left: 1,
            // }
          }}
          // detailPanel={[
          //   {
          //     tooltip: "S43343434",
          //     render: (rowData) => {
          //       return (
          //         <div>
          //           <Page_CafePC_PCHardware__Cafe hardware={rowData.Item} />{" "}
          //         </div>
          //       );
          //     },
          //   },
          // ]}
          // onRowClick={(evt, selectedRow) => {

          // }}
          actions={
            [
              // {
              //   icon: "save",
              //   tooltip: "Save User",
              //   onClick: (event, rowData) => {
              //     // alert("You saved " + rowData.id)
              //     // handleClickOpen_1_1();
              //     // setSelectedRecord_Id(rowData.id)
              //   },
              // },
            ]
          }
          components={
            {
              // Action: (props) => (
              // <Button
              //   onClick={(event) => props.action.onClick(event, props.data)}
              //   color="primary"
              //   variant="contained"
              //   style={{ textTransform: "none" }}
              //   size="small"
              // >
              //   EDIT
              // </Button>
              // ),
            }
          }
        />
      </Modal>

      <div style={{ marginLeft: "2rem" }}>
        <MaterialTable
          title={`Hardware Log 2 (${dbData?.length})  `}
          columns={state.columns}
          data={dbData}
          style={{
            color: "white",
            background: "rgba(0, 0, 0, .3)",
            // backdropFilter: "blur( 11.0px )",
            // "&::webkit-backdrop-filter": {
            //   webkitBackdropFilter: "blur( 18.0px )",
            // },
          }}
          options={{
            exportButton: true,
            headerStyle: {
              background: "rgba(0, 0, 0, .4)",

              border: "none",
            },
            // rowStyle: (rowData) => ({
            //   backgroundColor:
            //     selectedRow === rowData.tableData.id ? "#422400" : "",
            //   fontSize: 12,
            // }),
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData?.Item_stock_balance < 0
                  ? "#ff0000"
                  : rowData.Item_stock_balance === 0
                  ? "#1a0000"
                  : "",
              color: rowData?.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
              // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
              fontSize: 12,
            }),
            filtering: true,
            // grouping: true,
            exportAllData: true,
            pageSize: 100,
            pageSizeOptions: [20, 50, 100, 200, 500, 1000],
            paginationType: "stepped",
            // actionsColumnIndex: -1
            // fixedColumns: {
            //     left: 1,
            // }
          }}
          // detailPanel={[
          //   {
          //     tooltip: "S43343434",
          //     render: (rowData) => {
          //       return (
          //         <div>
          //           <Page_CafePC_PCHardware__Cafe hardware={rowData.Item} />{" "}
          //         </div>
          //       );
          //     },
          //   },
          // ]}
          // onRowClick={(evt, selectedRow) => {

          // }}
          actions={
            [
              // {
              //   icon: "save",
              //   tooltip: "Save User",
              //   onClick: (event, rowData) => {
              //     // alert("You saved " + rowData.id)
              //     // handleClickOpen_1_1();
              //     // setSelectedRecord_Id(rowData.id)
              //   },
              // },
            ]
          }
          components={
            {
              // Action: (props) => (
              // <Button
              //   onClick={(event) => props.action.onClick(event, props.data)}
              //   color="primary"
              //   variant="contained"
              //   style={{ textTransform: "none" }}
              //   size="small"
              // >
              //   EDIT
              // </Button>
              // ),
            }
          }
        />
      </div>
    </>
  );
}
