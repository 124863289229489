import React, { useState, useEffect, useRef, Children } from "react";
import axios from "axios";
import moment from "moment";

import "../Styles/hnd.css";

import MaterialTable from "material-table";
import {
  Table,
  Button as ButtonMT,
  Checkbox,
  Tooltip,
  Spoiler,
  TextInput,
  Button,
  Collapse,
} from "@mantine/core";

import { FaEnvira } from "react-icons/fa";
import { FaPlug } from "react-icons/fa";

const { REACT_APP_ECLIPSE_API, REACT_APP_ECLIPSE_API_LOCAL } = process.env;

const Page_HeroesNeverDie = () => {
  //  ----------------------------------------------------------------------------------------- database

  const [DB_Data, setDB_Data] = useState([]);
  const get_DB_Data = () => {
    axios
      //REACT_APP_ECLIPSE_API
      .post(`${REACT_APP_ECLIPSE_API}/get_HealthCheck_Status`, {})
      .then((res) => {
        console.log("get_DB_Data", res?.data);
        setDB_Data(res?.data);
      });
  };
  //----------------------------------------------------------------------------------------- table column

  let _headerStyle = {
    maxWidth: "2.4rem",
    padding: ".5rem",
  };
  let _cellStyle = {
    maxWidth: "0px",
    padding: "0.5rem",
  };
  let _filterCellStyle = {
    maxWidth: "0px",
    padding: "0.5rem",
  };

  //health_type, pc_name, pc_ip, pc_mac, PS, PV12, TBR, WM
  const [state, setState] = React.useState({
    columns: [
      // {
      //   title: "!",
      //   field: "total_offline_count",

      //   headerStyle: _headerStyle,
      //   cellStyle: _cellStyle,
      //   filterCellStyle: _filterCellStyle,
      //   render: (rowData) => (
      //     <div>
      //       {rowData?.total_offline_count !== 0
      //         ? `${rowData?.total_offline_count}🚨`
      //         : ""}
      //     </div>
      //   ),
      // },
      {
        title: "Health Type ",
        field: "health_type",
        headerStyle: {
          maxWidth: "1px",
        },
        render: (rowData) => (
          <div>
            <Button
              variant="subtle"
              color={rowData?.total_offline_count !== 0 ? "red" : "lime"}
              onClick={() => alert(rowData?.health_tips)}
            >
              {rowData?.total_offline_count !== 0
                ? `${rowData?.total_offline_count}🚨`
                : ""}
              {rowData?.health_type} ❔
            </Button>
          </div>
        ),
      },

      {
        title: "BBA",
        field: "BBA",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.BBA}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "BBK",
        field: "BBK",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.BBK}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "BA",
        field: "BA",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.BA}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "CP",
        field: "CP",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.CP}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "EP",
        field: "EP",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.EP}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "EVE",
        field: "EVE",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.EVE}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "IMP",
        field: "IMP",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.IMP}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "KJ",
        field: "KJ",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.KJ}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "KB",
        field: "KB",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.KB}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "KD",
        field: "KD",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.KD}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "LM",
        field: "LM",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.LM}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "MJ",
        field: "MJ",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.MJ}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "NIR",
        field: "NIR",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.NIR}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "OUG",
        field: "OUG",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.OUG}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "PI",
        field: "PI",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.PI}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "SA",
        field: "SA",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.SA}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "SB",
        field: "SB",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.SB}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "SD",
        field: "SD",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.SD}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "SL",
        field: "SL",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.SL}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "SM",
        field: "SM",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.SM}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "ST",
        field: "ST",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.ST}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "PS",
        field: "PS",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.PS}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "PV12",
        field: "PV12",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.PV12}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "TBR",
        field: "TBR",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.TBR}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "WM",
        field: "WM",
        headerStyle: _headerStyle,
        cellStyle: _cellStyle,
        filterCellStyle: _filterCellStyle,
        render: (rowData) => (
          <div>
            <RenderStatus
              p_data={rowData?.WM}
              p_cycle={rowData?.health_cycle}
              p_health_type={rowData?.health_type}
            />
          </div>
        ),
      },
      {
        title: "cycle",
        field: "health_cycle",
        headerStyle: {
          maxWidth: "1px",
        },
        render: (rowData) => (
          <div>{convertMinutesToDHMS(rowData?.health_cycle)}</div>
        ),
      },
      {
        title: "Target ",
        field: "health_target",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "pc name ",
        field: "pc_name",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "pc ip ",
        field: "pc_ip",
        headerStyle: {
          maxWidth: "1px",
        },
      },
      {
        title: "pc mac ",
        field: "pc_mac",
        headerStyle: {
          maxWidth: "1px",
        },
      },
    ],
  });
  const convertMinutesToDHMS = (totalMinutes) => {
    const duration = moment.duration(totalMinutes, "minutes");
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    const parts = [
      days > 0 ? `${days}d` : "",
      hours > 0 ? `${hours}h` : "",
      minutes >= 0 ? `${minutes}m` : "",
    ];

    return parts.reduce(
      (acc, part) => (part ? acc + (acc ? ", " : "") + part : acc),
      ""
    );
  };

  const RenderStatus = ({ p_data, p_cycle, p_health_type }) => {
    // if (p_cycle === undefined) return "-1";
    let result = "";
    // if (p_health_type === null || p_health_type === undefined) result = "";
    if (p_health_type === "speedtest") {
      result = (
        <>
          <div className="hnd__FlexRow">
            <div>D</div>
            <div
              style={{ color: p_data?.split("|")?.[0] > 0.7 ? "lime" : "red" }}
            >
              {p_data?.split("|")?.[0]}
            </div>
          </div>
          <div className="hnd__FlexRow">
            <div>U</div>
            <div
              style={{ color: p_data?.split("|")?.[0] > 0.7 ? "lime" : "red" }}
            >
              {p_data?.split("|")?.[1]}
            </div>
          </div>
          <div className="hnd__FlexRow">
            <div>ms</div>
            <div
              style={{ color: p_data?.split("|")?.[0] < 4 ? "lime" : "red" }}
            >
              {p_data?.split("|")?.[2]?.split("::")?.[0]}
            </div>
          </div>
          <br />
          <div>
            {convertMinutesToDHMS(p_data?.split("|")?.[2]?.split("::")?.[1])}
          </div>
        </>
      );
    } else if (p_health_type === "ygx hdd") {
      result = p_data
        ?.split("||")
        .filter((x) => {
          return x?.split("|")?.[1]?.split("/")?.[0] < 10;
        })
        ?.map((x) => {
          return `${x.replace(",", "")?.[0]},${
            x?.split?.("|")?.[1]?.split("/")?.[0]
          }`;
        })
        ?.map((x) => {
          return (
            <div className="hnd__FlexRow">
              <div>{x?.split(",")?.[0]}</div>
              <div style={{ color: "red" }}>{`${parseFloat(
                x?.split(",")?.[1]
              )?.toFixed(0)}G`}</div>
            </div>
          );
        });

      // console.log("result", `[${result}]`);
      result =
        result?.length > 0 ? (
          <div className="hnd__VerticalAlignBottom">
            <div>{result}</div>
            <br />
            <div>{convertMinutesToDHMS(p_data?.split("::")?.[1])}</div>
          </div>
        ) : (
          <div className="hnd__VerticalAlignBottom">
            <div className="hnd_gradient">☘︎</div>

            <div>{convertMinutesToDHMS(p_data?.split("::")?.[1])}</div>
          </div>
        );
    } else {
      result =
        p_data !== null ? (
          parseInt(p_data) <= parseInt(p_cycle) ? (
            <>
              <div className="hnd_gradient">☘︎</div>

              {`${convertMinutesToDHMS(p_data) ?? "?"} `}
            </>
          ) : (
            <>
              <FaPlug style={{ color: "red" }} />

              {`${convertMinutesToDHMS(p_data) ?? "?"}`}
              {/* {`[${p_data}:${p_cycle}]`} */}
            </>
          )
        ) : (
          <>
            <FaEnvira style={{ color: "gray" }} />
          </>
        );
    }

    return <div className="">{result}</div>;
  };

  useEffect(() => {
    get_DB_Data();
  }, []);

  return (
    <div className="hnd_body">
      <h1>Page_HeroesNeverDie</h1>
      <br />
      <FaEnvira style={{ color: "lime" }} /> online ( within 10min ),
      <FaPlug style={{ color: "red" }} /> offline ( more then 10min ),
      <FaEnvira style={{ color: "gray" }} /> no set config at eclipse hnd
      <br />
      Targrt , from Eclipse HND (self) ping or check (other) exe
      <br />
      Eclipse HND - auto sync status every 10min
      <MaterialTable
        title={
          <Button
            variant="outline"
            color="orange"
            onClick={() => {
              get_DB_Data();
            }}
          >
            Reload Data
          </Button>
        }
        columns={state.columns}
        data={DB_Data}
        style={{
          color: "white",
          background: "rgba(36, 43, 38, .5)",
          // backgroundColor: "rgba(0,0,0,.1)",
          // backgroundColor:
          //   "linear-gradient(90deg,rgba(25, 30, 26,.3) .25%, rgba(0, 0, 0,.5) .25%)",
          // backdropFilter: "blur( 11.0px )",
          // "&::webkit-backdrop-filter": {
          //   webkitBackdropFilter: "blur( 18.0px )",
          // },
        }}
        options={{
          exportButton: true,
          padding: "dense",
          headerStyle: {
            background: "rgba(54, 64, 56,.5)",
            border: "none",
          },
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData?.hide_pc === 1 ? "darkred" : "rgba(25, 30, 26,.5)",

            color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
            // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
            fontSize: 12,
          }),
          filtering: true,
          grouping: true,
          exportAllData: true,
          pageSize: 100,
          pageSizeOptions: [20, 50, 100, 200, 500, 1000],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
        }}
        // onRowClick={(evt, selectedRow) => {
        // console.log(selectedRow.id);
        // setSelected_Item(selectedRow);
        // handleClickOpen_1_1();
        // setSelected_Input_OrderId(selectedRow.order_id);
        // getInfo_By_OrderId_1_1_notSold(selectedRow.order_id);
        // getInfo_By_OrderId_1_1_alreadySold(selectedRow.order_id);
        // }}
        detailPanel={
          [
            // {
            //   tooltip: "Show Hardware Changes Log",
            //   render: (rowData) => {
            //     // console.log("rowData", rowData);
            //     return (
            //       <div>
            //         <Comp_CafePC_PCHardware_Log_v2
            //           cafe={rowData?.cafe}
            //           pc_name={rowData?.PC_Name}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]
        }
        actions={
          [
            // {
            //   icon: "save",
            //   tooltip: "Save User",
            //   onClick: (event, rowData) => {
            //     // get_DB_CafeHardware_StatusLog_byCafePCName(
            //     //   rowData?.cafe,
            //     //   rowData?.PC_Name
            //     // );
            //     setSelectedRow(rowData);
            //     setDialog_Show(true);
            //     console.log("rowData", rowData);
            //   },
            // },
          ]
        }
        components={
          {
            // Action: (props) => (
            //   <Button
            //     // disabled
            //     onClick={(event) => props.action.onClick(event, props.data)}
            //     color="inherit"
            //     variant="text"
            //     style={{ textTransform: "none" }}
            //     size="large"
            //   >
            //     <div style={{ fontSize: "2rem" }}>🚧</div>
            //   </Button>
            // ),
          }
        }
      />
    </div>
  );
};

export default Page_HeroesNeverDie;
